/* eslint-disable no-undef */
/* eslint-disable no-console */
import { Auth0Provider } from '@auth0/auth0-react';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useNavigate } from 'react-router-dom-v5-compat';

import { CircularProgress, Error, initializeNewRelic, PostHogProvider, sfPosthog } from '@sigfig/digital-wealth-core';

import * as serviceWorker from './serviceWorker';

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = appState => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const App = () => {
  const [config, setConfig] = useState(null);

  // Dynamically import the appropriate app
  const AppToUse = lazy(() => {
    if (process.env.REACT_APP_MODE === 'client') {
      return import('./ClientApp');
    } else if (process.env.REACT_APP_MODE === 'operation') {
      return import('./OperationApp');
    } else {
      return import('./AdvisorApp');
    }
  });

  // Dynamically import the appropriate app
  const configToUse = () => {
    if (process.env.REACT_APP_MODE === 'client') {
      return import('./ClientApp/config');
    } else if (process.env.REACT_APP_MODE === 'operation') {
      return import('./OperationApp/config');
    } else {
      return import('./AdvisorApp/config');
    }
  };

  const getLogLevels = isProd => {
    if (isProd) {
      return ['error'];
    }
    if (process.env.NODE_ENV === 'development') {
      return [];
    }
    return ['warn', 'error'];
  };

  useEffect(() => {
    const loadConfig = async () => {
      const configModule = await configToUse();
      setConfig(configModule.default);

      // Initialize New Relic and Sentry after config is loaded
      if (configModule.default && process.env.REACT_APP_MODE !== 'operation') {
        initializeNewRelic(configModule.default.newRelic.licenseKey, configModule.default.newRelic.applicationId);
        Sentry.init({
          dsn: configModule.default.sentry.dsn,
          environment: configModule.default.sentry.environment,
          integrations: [
            new Integrations.BrowserTracing(),
            new CaptureConsoleIntegration({
              levels: getLogLevels(configModule.isProd),
            }),
          ],
          tracesSampleRate: 1.0,
        });
      }
    };

    loadConfig();
  }, []);

  if (!config) {
    return <CircularProgress />;
  }

  return (
    <Auth0ProviderWithRedirectCallback
      clientId={config.auth0.clientId}
      domain={config.auth0.domain}
      redirectUri={window.location.origin}
    >
      <Sentry.ErrorBoundary fallback={Error}>
        <PostHogProvider apiKey={config.posthog.token} options={sfPosthog.DEFAULT_CONFIG}>
          <AppToUse />
        </PostHogProvider>
      </Sentry.ErrorBoundary>
    </Auth0ProviderWithRedirectCallback>
  );
};

ReactDOM.render(
  <Suspense fallback={<CircularProgress disableShrink />}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Suspense>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
